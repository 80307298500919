(function ($) {
  Drupal.behaviors.basicToutV1 = {
    attach: function (context) {
      var $modules = $('.js-content-block-tout--v1', context);

      $modules.each(function (index) {
        var $module = $(this);
        var $open = $('.js-tout-details', $module);
        var $close = $('.js-tout-details-close', $module);

        $open.on('click.open', function (event) {
          event.preventDefault();
          $module.toggleClass('details-active');
        });
        $close.on('click.close', function (event) {
          event.preventDefault();
          $module.removeClass('details-active');
        });
      });
    }
  };
})(jQuery);
